import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSession from 'vue-session'
import vuetify from "./plugins/vuetify";
import axios from './plugins/axios'
import VueAxios from 'vue-axios'
import Toasted from 'vue-toasted';
import CKEditor from 'ckeditor4-vue';
import { serialize } from 'object-to-formdata';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'


Vue.config.productionTip = false;

Vue.use(VueSession,{
  persist:true
})
Vue.use(VueAxios,axios)
Vue.use(Toasted, {
  iconPack:'mdi',
  position:'bottom-right',
  duration:5000
});

Vue.use(CKEditor);

moment.locale('es');
moment.tz.setDefault("America/Lima");
Vue.use(VueMoment, {
  moment,
})

Vue.mixin({
  methods: {
    serialize,
    assetAWS:(url=null)=>{
      return process.env.VUE_APP_AWS_URL + url;
    },
    apiUrl:(url=null)=>{
      return process.env.VUE_APP_URL_API + url;
    },
    validarPermiso(valor) {
      let sessionPermisos = this.$session.get("jsPermissions");

      if (sessionPermisos != 0 && sessionPermisos != null && sessionPermisos != "0"&& sessionPermisos != undefined) {
        let permisosRoles = JSON.parse(sessionPermisos);
        let permisos = permisosRoles.permissions;
        let roles = permisosRoles.roles;
        return permisos.includes(valor);
      }
    },
  },
})



new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
