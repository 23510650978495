import Vue from 'vue'
import axios from 'axios'
import router from '../router'


//configuracion axios
axios.interceptors.request.use(config => {
    config.baseURL = process.env.VUE_APP_URL_API;
    config.headers.common['Authorization'] = 'Bearer '+ Vue.prototype.$session.get('token');
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {

    if (error.response.status === 401) {
      
      Vue.prototype.$session.clear();
      router.push({ name: 'loginPanel' })
      
    }

    return Promise.reject(error);
  });
  
  //configuracion axios


  export default axios;